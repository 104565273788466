<template>
  <Overview 
        :moduleName="$tc(navName, 1)"
        :moduleNamePlural="$tc(navName, 2)"
        sort="email"
        :newButtonName="$t('form.add', [$t(navName)])"
        :filterOptions="filterOptions"
        :selectFields=selectFields
        :itemService=itemService
        :itemFormRef=itemFormRef
        ref="overview"
    >
        <template #columns>
            <Column field="email" :header="$t('form.email')" :sortable="true" style="width: 280px">
                <template #body="slotProps">
                    <div v-if="slotProps.data.profile_picture" class="uploaded-logo uploaded-logo-sm me-2" :style="{'background-image': `url(${slotProps.data.profile_picture})`}"></div>
                    <div v-else class="rounded-circle account-dropdown me-1 text-light me-2">{{getInitials(slotProps.data)}}</div>
                    {{slotProps.data.email}}
                </template>
            </Column>
            <Column field="name" :header="$t('form.first_name')" :sortable="true" style="width: 150px">
                <template #body="slotProps">
                    {{slotProps.data.name.replace(/\s.+/, '')}}
                </template>
            </Column>
            <Column field="name" :header="$t('form.last_name')" :sortable="true" style="width: 150px">
                <template #body="slotProps">
                    {{slotProps.data.name.replace(/^.+?\s/, '')}}
                </template>
            </Column>
            <Column field="dealer.name" :header="$t('navigation.dealers')" :sortable="false">
                <template #body="slotProps">
                    {{slotProps.data.user_type_id == 3 && slotProps.data.dealer ? slotProps.data.dealer.name : ''}}
                </template>
            </Column>
            <Column field="user_type_id" :header="$t('navigation.customers')" :sortable="false" style="width: 90px">
                <template #body="slotProps">
                    <i class="fa-regular" :class="{'fa-user': slotProps.data.user_accesses.length == 1, 'fa-user-group': slotProps.data.user_accesses.length > 1}"></i>
                </template>
            </Column>
            <Column field="user_type_id" :header="$t('overview.usergroup')" :sortable="false" style="width: 150px">
                <template #body="slotProps">
                    <span v-show="slotProps.data.user_type_id">{{$t('user_types.'+slotProps.data.user_type_id)}}</span>
                </template>
            </Column>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import dsService from '@/services/UserService';
import DealerService from '@/services/DealerService';
import store from '@/store/user'

export default {
    components: {
        Overview,
        Column
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$refs.overview.getIndex(from);
        })
    },
    data() {
        return {
            item: {},
            itemService: dsService,
            itemFormRef: null,
            navName: 'navigation.users',
            companyTypes: [],
            selectFields: [],
            filterOptions: [
                {
                    field: 'dealer_id',
                    service: DealerService,
                    name: this.$t('navigation.dealers'),
                    multiple: true
                },
                {
                    field: 'user_type_id',
                    checkboxes: [
                        {id: 1, name: this.$t('user_types.1')},
                        {id: 2, name: this.$t('user_types.2')},
                        {id: 3, name: this.$t('user_types.3')}],
                    name: this.$t('overview.usergroup')
                }
            ],
        }
    },
    methods: {
        getInitials(data){
            const splittedName = data.name.split(' ');
            return splittedName[0].charAt(0) + splittedName[splittedName.length - 1].charAt(0);
        }
    },
    mounted() {
        //Not customers allowed
        if(store.getters.getUserType == 2){
            this.$router.push({name: 'Home'});
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>